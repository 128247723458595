<template lang="pug">
  svg#iug_cross(width='11' height='11' viewBox='0 0 11 11' fill='none' xmlns='http://www.w3.org/2000/svg')
    path(:fill='changeFill' d='M6.8685 5.45525L10.6155 1.70725C11.0065 1.31725 11.0075 0.68625 10.6155 0.29325C10.2245 -0.09775 9.5925 -0.09775 9.2015 0.29325L5.4545 4.04125L1.7085 0.29525C1.3165 -0.0967501 0.683501 -0.0967501 0.293501 0.29425C-0.0984995 0.68625 -0.0974995 1.31825 0.2945 1.70925L4.0405 5.45525L0.2945 9.20125C-0.0974995 9.59425 -0.0974995 10.2262 0.293501 10.6172C0.685501 11.0082 1.3165 11.0082 1.7085 10.6152L5.4545 6.86925L9.2015 10.6172C9.5925 11.0082 10.2235 11.0082 10.6155 10.6172C11.0065 10.2262 11.0065 9.59425 10.6155 9.20325L6.8685 5.45525Z')
</template>

<script>
export default {
  name: 'IUG_CrossSVG',
  props: {
    isClickedCross: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: '#667b87',
    },
  },
  computed: {
    changeFill() {
      if (this.isClickedCross) {
        return '#ffffff';
      } else {
        return this.color;
      }
    }
  },
};
</script>